<template>
    <div class="box">
        <div class="laws">
            <h1>Lei</h1>
            <p>
                No Brasil, o tema bullying começou a ser debatido com mais efetividade nas
                duas últimas décadas. Em termos de Leis em 2015 foi criado a lei 13.185/15
                que institui o programa de combate à intimidação sistemática (Bullying) em
                todo o território nacional.
            </p>

            <p class="cit">
                Art. 1º Fica instituído o Programa de Combate à Intimidação Sistemática
                (Bullying) em todo o território nacional.
            </p>

            <p class="cit">
                § 1º No contexto e para os fins desta Lei, considera-se intimidação
                sistemática (bullying) todo ato de violência física ou psicológica,
                intencional e repetitivo que ocorre sem motivação evidente, praticado por
                indivíduo ou grupo, contra uma ou mais pessoas, com o objetivo de
                intimidá-la ou agredi-la, causando dor e angústia à vítima, em uma relação
                de desequilíbrio de poder entre as partes envolvidas.
            </p>

            <p>
                Em 2016, por meio da Lei 13.277, foi instituída a data de 07 de abril como
                o Dia Nacional de Combate ao Bullying e à violência nas escolas.
                Recentemente a Lei 13.663/2018 inclui dentre as atribuições das escolas, a
                promoção da cultura da paz e medidas de conscientização, prevenção e
                combate a diversos tipos de violência, como o bullying (BRASIL, 2018).
                Essa lei acrescenta dois incisos ao Art. 12 da Lei de Diretrizes e Bases
                da Educação Nacional (LDB 9.394/1996) e reforça a lei 13.185/2015.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LawsBullying'
    };
</script>

<style scoped>
    .box {
        max-width: 600px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .laws h1 {
        margin-bottom: 20px;
    }

    .laws p {
        font-size: 20px;
        text-align: justify;
        letter-spacing: 0em !important;
    }

    .laws .cit {
        margin-left: 100px;
        font-size: 14px;
    }
</style>
